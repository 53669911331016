@import 'https://fonts.googleapis.com/css?family=Karla';
:root {
  font-size: 13px;
}

@media screen and (min-width: 1000px) {
  :root {
    font-size: 14px;
  }
}
@media screen and (min-width: 1240px) {
  :root {
    font-size: 16px;
  }
}
@media screen and (min-width: 1480px) {
  :root {
    font-size: 17px;
  }
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Karla";
  font-size: 1rem;
  font-weight: 300;
  color: #EEEEEE;
  line-height: 2em;
  background: #EEEEEE;
}

.main {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  background: linear-gradient(120deg, #383745 50%, #22202B 50%);
}
.main .header,
.main legend.header {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.25rem;
  margin: 0.5em 0;
  color: #a94dff;
}

legend {
  color: #a94dff;
}

#name-input {
  color: #a94dff;
  border-color: #a94dff;
}

#email-input {
  color: #a94dff;
  border-color: #a94dff;
  border-radius: 5%;
}

#btn-submit {
  background-color: #a94dff;
  color: #EEEEEE;
}

#message-input {
  color: #a94dff;
  border-color: #a94dff;
  min-height: 8vh
}

#budzet {
  color: #a94dff;
  border-color: #a94dff;

}

#aftersend {
  max-width: 50px;
}

.about {
  max-width: 40vh;
}

.main .bio,
.main .contact {
  max-width: 100%;
  margin-top: 10vh;
}
.main .bio .profile-img {
  max-width: 7em;
  border-radius: 50%;
}
.main .bio .bio-link {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  display: inline-block;
  clear: left;
  text-decoration: none;
  color: #EEEEEE;
  margin-bottom: 0.25em;
  transition: color 300ms ease-in-out;
}
.main .bio .bio-link i {
  margin-left: 8px;
}
.main .bio .bio-link::after {
  display: block;
  content: "";
  height: 1px;
  width: 0;
  background: #CA8D6E;
  transition: width 300ms ease-in-out;
}
.main .bio .bio-link:hover::after {
  width: 100%;
}
.main .bio .bio-link:hover {
  color: #CA8D6E;
}
.main .contact {
  color: #a94dff;
}
.main .contact form fieldset {
  font-size: 1rem;
  position: relative;
  border: none;
  margin-bottom: 0.25em;
  padding: 0.25em 0;
}
.main .contact form fieldset label {
  font-size: 0.875rem;
  display: block;
  position: absolute;
  right: 0.125em;
  top: 1.5em;
  color: #a94dff;
}
.main .contact form input,
.main .contact form textarea,
.main .contact form button {
  border: 0;
}
.main .contact form input:focus,
.main .contact form textarea:focus,
.main .contact form button:focus {
  outline: none;
}
.main .contact form input,
.main .contact form textarea {
  display: block;
  font-family: "Karla";
  font-size: 1rem;
  padding: 0.5em 0;
  width: 280px;
  background: none;
  color: #a94dff;
  border-bottom: 1px solid #7D6962;
  transition: border 300ms ease-in-out;
}
.main .contact form input:focus,
.main .contact form textarea:focus {
  border-bottom: 1px solid #a94dff;
}
.main .contact form input::-moz-placeholder, .main .contact form textarea::-moz-placeholder {
  color: #7D6962;
}
.main .contact form input:-ms-input-placeholder, .main .contact form textarea:-ms-input-placeholder {
  color: #7D6962;
}
.main .contact form input::placeholder,
.main .contact form textarea::placeholder {
  color: #7D6962;
}
.main .contact button {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  display: block;
  background: #CA8D6E;
  color: #EEEEEE;
  padding: 1em 2em;
  box-shadow: 0 0 40px 0 rgba(21, 21, 21, 0.6);
}
.main .contact button:hover {
  -webkit-animation: swell 500ms ease-in-out;
          animation: swell 500ms ease-in-out;
  cursor: pointer;
}
.main .contact textarea {
  resize: none;
}

.main {
  /*overflow: hidden;*/
  -webkit-animation: Page 600ms ease-in-out forwards;
          animation: Page 600ms ease-in-out forwards;
}

.bio {
  -webkit-animation: Bio 600ms ease-in-out forwards;
          animation: Bio 600ms ease-in-out forwards;
}

.contact {
  -webkit-animation: Contact 600ms ease-in-out forwards;
          animation: Contact 600ms ease-in-out forwards;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 25px; 
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}
label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 25px; 
  background-repeat: no-repeat;
  background-position: right 10px center;
}

textarea {
  resize: none; 
  height: 40px; 
  overflow: hidden;
  padding: 10px; 
  box-sizing: border-box; 
}

textarea:focus {
  outline: none; 
  box-shadow: 0 0 0 2px #007bff; 
}

@-webkit-keyframes Page {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Page {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes Bio {
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes Bio {
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes Contact {
  0% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes Contact {
  0% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes swell {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swell {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
